// src/pages/home.jsx
import React from 'react';
import Head from '../components/head';
const Home = () => (
<main>
<Head
title="Home - AMIX Wealth Management"
/>
  <section id="intro" className="wrapper style1">
		<div className="title">
		<h1 className="page-title">Welcome to AMIX</h1>
		</div>
		<div className="container">
			<p className="style2">
				Your Trusted Partner for <br className="mobile-hide" />Taxation and Financial Success
			</p>
			<p className="style3">At AMIX Wealth Management, we simplify tax returns, build lasting wealth, and provide expert taxation guidance for individuals and businesses. Whether you need tax solutions or strategic advice, we’re here to help you succeed.</p>
			<ul className="actions">
				<li><a href="/contact-us" className="button style3 large">Contact Us</a></li>
				<li><a href="/about-us" className="button style3 large">More Info</a></li>
			</ul>
		</div>
	</section>


	<section id="main" className="wrapper style2">
		<div className="title">Services</div>
		<div className="container">

			
				<a href="#" className="image featured">
					<img src="images/pic01.jpg" alt="" />
				</a>

			
				<section id="features">
					<header className="style1">
						<h2>Empower Your Financial Future with AMIX Wealth Management</h2>
						<p>Comprehensive Tax, Advisory, and Wealth Management Solutions for Individuals and Businesses</p>
					</header>
					<div className="feature-list">
						<div className="row">
							<div className="col-6 col-12-medium">
								<section>
									<h3 className="icon solid fa-hand-holding-usd">Taxation</h3>
									<p>AMIX offers full taxation services relieving the stress of managing your taxation affairs. </p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
								<section>
									<h3 className="icon solid fa-file-alt">Virtual CFO</h3>
									<p>AMIX provide management accounting functions to deliver results on a timely and reliable basis.</p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
								<section>
									<h3 className="icon solid fa-university">Corporate tax planning</h3>
									<p>AMIX specialises in optimising tax management for businesses.</p>
								</section>
							</div>
							<div className="col-6 col-12-medium">
								<section>
									<h3 className="icon fa-chart-line">
										Wealth Management</h3>
									<p>We provide collaborative wealth management support.</p>
								</section>
							</div>
						</div>
					</div>
					<ul className="actions special">
						<li><a href="/contact-us" className="button style1 large">Contact Us</a></li>
						<li><a href="/services" className="button style2 large">More Info</a></li>
					</ul>
				</section>

		</div>
	</section>

</main>
);

export default Home;