// src/pages/notfound.jsx
import Head from '../components/head';
const NotFound = () => (
  <main>
    <Head
title="Page Not Found - AMIX Wealth Management"
/>
    <section id="main" className="wrapper style2">
      <div className="title">
      <h1 className="page-title">Page Not Found</h1>
      <nav aria-label="breadcrumb" className="breadcrumb-container">
        <ol className="breadcrumb slim-breadcrumb">
          <li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> AMIX</a></li>
          <li className="breadcrumb-item active" aria-current="page"><i className="fas fa-exclamation-triangle"></i> Not Found</li>
        </ol>
      </nav>
      </div>
      <div className="container">
          <section id="features">
            <header className="style1">
              <h2>Oops!</h2>
              <p>It seems you’ve navigated to a page that doesn’t exist. At AMIX Wealth Management, we’re committed to guiding you toward smarter taxation decisions. Let us help you get back on track. Visit our <a href="/">homepage</a> or explore the services we offer to secure your financial future.</p>
            </header>
          </section>      
    </div>
    </section>       
  </main>
);

export default NotFound;