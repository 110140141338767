import React from 'react';
import Head from '../components/head';
const handleToggleClick = (event) => {
    event.preventDefault();
    // Prevents the default anchor tag behavior.
  };
const Services = () => (
<main>
<Head
title="Services - AMIX Wealth Management"
/>
<section id="main" className="wrapper style2 py-5 feature container-fluid">
		<div className="title">
        <h1 className="page-title">Services</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> AMIX</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-cogs"></i> Services</li>
			</ol>
		</nav>
        </div>
		<div className="container pb-5">
                <header className="style1">
                    <h2>Empower Your Financial Future with AMIX Wealth Management</h2>
                    <p className="mb-0 text-center">Comprehensive Tax, Advisory, and Wealth Management Solutions for Individuals and Businesses.
                    </p>
                </header>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay="0.8s" >
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-hand-holding-usd fa-4x text-primary"></i>
                            </div>
                            <h4>Taxation</h4>
                            <p className="mb-4">AMIX offers full taxation services relieving the stress of managing your taxation affairs. 
                            </p>
							<div className="collapse mt-3 text-start" id="moreInfo-4">
								<p>We work to provide the best advice whether the matter is complex or procedural.</p>
							</div>
                            <a className="button style1 rounded-pill" href="#"  data-bs-toggle="collapse" data-bs-target="#moreInfo-4" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>Learn More</a>
                        </div>
                    </div>
					<div className="col-md-6 col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-file-alt fa-4x text-primary"></i>
                            </div>
                            <h4>Virtual CFO</h4>
                            <p className="mb-4">AMIX provide management accounting functions to deliver results on a timely and reliable basis.  
                            </p>
							<div className="collapse mt-3 text-start" id="moreInfo-3">
								<p>We act on behalf of a number of Australian and International organisations, managing outsourced CFO functions.</p>
							</div>
                            <a className="button style1 rounded-pill" href="#" data-bs-toggle="collapse" data-bs-target="#moreInfo-3" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>Learn More</a>
                        </div>
                    </div>
					<div className="col-md-6 col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-university fa-4x text-primary"></i>
                            </div>
                            <h4>Corporate tax planning</h4>
                            <p className="mb-4 text-start">AMIX specialises in optimising tax management for businesses.
                            </p>
							<div className="collapse mt-3 text-start" id="moreInfo-2">
								<p>We use strategic approaches like identifying eligible deductions, refining entity structures, and ensuring full tax compliance. Our services include advising on tax issues, managing capital gains, and assessing the tax impact of key business decisions. The aim is to enhance financial efficiency, foster growth, and minimise tax risks within legal boundaries.</p>
							</div>
                            <a className="button style1 rounded-pill" href="#" data-bs-toggle="collapse" data-bs-target="#moreInfo-2" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>Learn More</a>
                        </div>
                    </div>
					<div className="col-md-6 col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="feature-item p-4">
                            <div className="feature-icon p-4 mb-4">
                                <i className="fas fa-chart-line fa-4x text-primary"></i>
                            </div>
                            <h4>Wealth Management</h4>
                            <p className="mb-4 text-start">
							We provide collaborative wealth management support.
                            </p>
							<div className="collapse mt-3 text-start" id="moreInfo-1">
							<p>Working closely with your financial advisory team, we ensure seamless alignment between your business and personal wealth strategies. Our goal is to keep your financial plans cohesive and focused on your success.</p>
							</div>
                            <a className="button style1 rounded-pill" href="#" data-bs-toggle="collapse" data-bs-target="#moreInfo-1" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>Learn More</a>
                        </div>
                    </div>
                </div>
           
		</div>
	</section>
</main>
);

export default Services;