// src/pages/contactus.jsx
import React from 'react';
import Head from '../components/head';

const AboutUs = () => (
<main>
<Head
title="About Us - AMIX Wealth Management"
/>
	<section id="main" className="wrapper style2 about-main">
		<div className="title">
		<h1 className="page-title">About Us</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> AMIX</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-briefcase"></i> About Us</li>
			</ol>
		</nav>
		</div>
		<div className="container text-center">
		
		
		<header className="style1">
		<h2>Your Trusted Partner for Taxation and Financial Success</h2>
		<p>At AMIX Wealth Management, we simplify tax returns, build lasting wealth, and provide expert taxation guidance for individuals and businesses. Whether you need tax solutions or strategic advice, we’re here to help you succeed.</p>
		</header>

		<img src="images/banner-partners.jpg" alt="AMIX Wealth Management" className="img-fluid" />
		<div className="text-start">
		<p>Our firm leverages over 15 years of industry experience to provide exceptional financial services tailored for both individuals and businesses. </p>
		<p>
		We specialise in simplifying tax return processes, building lasting wealth, and offering expert financial guidance. Our dedicated team is committed to delivering customized solutions that address your unique financial needs. Whether you require effective tax strategies or comprehensive financial advice, we are here to support your success and help you navigate the complexities of the financial landscape with confidence. 
		</p>
		<p>Partner with us to achieve your financial aspirations and secure a prosperous future.</p>
		</div>
		<ul className="actions special">
			<li><a href="/contact-us" className="button style1 large">Contact Us</a></li>
			<li><a href="/our-team" className="button style2 large">Meet Our Team</a></li>
			<li><a href="/services" className="button style2 large">Explore Services</a></li>
		</ul>
		</div>


	</section>
	
</main>
);

export default AboutUs;