// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
            <section id="footer" className="wrapper">
                <div className="title">AMIX Wealth Management</div>
                <div className="container">
                    <header className="style1">
                        <h2>Let’s Connect and Discuss Your Financial Future</h2>
                        <p>
                        Whether you have questions about taxes or investment strategies, we’re here to help. Reach out to us to schedule a consultation, and let our experienced team guide you through the next steps in securing your financial well-being.
                        </p>
                    </header>
                    <div className="row">
                        
                        <div className="col-12 col-12-medium">

                          
                                <section className="feature-list small">
                                    <div className="row">
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-home">Address</h3>
                                                <p>
                                                Suite 1201<br />
                                                275 Alfred St,<br />
                                                North Sydney, NSW 2060
                                                </p>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-clock">Office Hours:</h3>
                                                <ul>
                                                    <li>Monday - Friday: 9:00 AM - 5:00 PM</li>
                                                    <li>Saturday - Sunday: Closed</li>
                                                </ul>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-envelope">Email</h3>
                                                <ul>
                                                <li>Terry: <a href="mailto:tliao@amix.com.au">tliao@amix.com.au</a></li>
                                                <li>Miranda: <a href="mailto:mwei@amix.com.au">mwei@amix.com.au</a></li>
                                                </ul>
                                                <p>
                                                    
                                                </p>
                                            </section>
                                        </div>
                                        <div className="col-6 col-12-small">
                                            <section>
                                                <h3 className="icon solid fa-phone">Phone</h3>
                                                <ul>
                                                <li>Terry: 0425 157 135</li>
                                                <li>Miranda: 0406 608 866</li>
                                                </ul>
                                                <p>
                                                
                                                </p>
                                            </section>
                                        </div>
                                    </div>
                                </section>

                        </div>
                    </div>
                    <div id="copyright">
                        <ul>
                            <li>&copy; 2024 AMIX Wealth Management Pty Ltd. All rights reserved.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
                        </ul>
                    </div>
                </div>
            </section>




    );
};
export default Footer;
