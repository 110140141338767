
import React from 'react';
import Head from '../components/head';
const handleToggleClick = (event) => {
    event.preventDefault();
    // Prevents the default anchor tag behavior.
  };
const OurTeam = () => (
<main>
<Head
title="Our Team - AMIX Wealth Management"
/>
<section id="main" className="wrapper style2 py-5 team4">
		<div className="title">
		<h1 className="page-title">Our Team</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> AMIX</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-handshake"></i> Our Team</li>
			</ol>
		</nav>
		</div>
		<div className="container">

    
	<header className="style1">
        <h2>Experienced & Professional Team</h2>
        <p className="mb-0 text-center">AMIX Wealth Management’s team specialises in taxation, offering expert guidance to simplify complex tax matters. Count on us for precise, professional service tailored to your financial goals.</p>
    </header>
    
    <div className="row">
	<div className="col-lg-6 mb-4">
      
	  <div className="row">
		<div className="col-md-12 text-center">
		  <img src="images/terry-liao-profile.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
		</div>
		<div className="col-md-12 text-center">
		  <div className="pt-2">
			<h5 className="mt-4 font-weight-medium mb-0">Terry Liao</h5>
			<h6 className="subtitle mb-3">Principle</h6>
			<p>With over 15 years of experience in this industry, I use my advanced knowledge in accounting, taxation, and relevant information technology to help businesses achieve their goals. </p>
			<div className="collapse mt-3" id="moreInfo-1">
				<p>This includes assisting small business owners in building their businesses from scratch and multiple national companies in entering Australia or expanding to Asia.</p>
				<p>My favourite part of my job is building tax-oriented business plans for business owners and high-net-worth individuals, which I then execute, monitor, and refine. This has not only increased the clients' wealth by optimising the tax outcomes but also shielded their wealth with a customised asset protection structure and family succession plan.</p>
				<p>The most exciting part of my job is witnessing how information technology revolutionises the way people run their businesses. Some will lose their jobs but some will master the change and become stronger. Are you ready?</p>
			</div>
			<ul className="actions">
				<li><a href="#" className="button style2" data-bs-toggle="collapse" data-bs-target="#moreInfo-1" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>More Info</a></li>
				<li><a href="/contact-us" className="button style1">Contact Us</a></li>
			</ul>
		  </div>
		</div>
	  </div>
  
	</div>


      <div className="col-lg-6 mb-4">
       
        <div className="row">
          <div className="col-md-12 text-center">
            <img src="images/miranda-wei-profile.jpg" alt="wrapkit" className="img-fluid rounded-circle" />
          </div>
          <div className="col-md-12 text-center">
            <div className="pt-2">
              <h5 className="mt-4 font-weight-medium mb-0">Miranda Wei</h5>
              <h6 className="subtitle mb-3">Principle</h6>
              <p>With over eight years of expertise in accounting and financial strategy, I am dedicated to helping business owners and high-net-worth individuals achieve their financial aspirations.</p>
              <div className="collapse mt-3" id="moreInfo-2">
				<p>I offer a comprehensive approach to wealth management, specializing in strategic tax planning, business analysis, and cash flow optimization. My focus is on providing solutions that not only protect and grow wealth but also secure long-term financial well-being.</p>
				<p>At AMIX, I prioritise positive, lasting impacts for our clients and our community. Through insightful guidance and proactive financial strategies, I empower my clients to make informed decisions, ensuring their assets are protected and their wealth is managed effectively. I am passionate about making a meaningful difference, driven by my commitment to excellence and integrity in everything I do.</p>
			</div>
			<ul className="actions">
				<li><a href="#" className="button style2" data-bs-toggle="collapse" data-bs-target="#moreInfo-2" aria-expanded="false" aria-controls="moreInfo" onClick={handleToggleClick}>More Info</a></li>
				<li><a href="/contact-us" className="button style1">Contact Us</a></li>
			</ul>
            </div>
          </div>
        </div>
    
      </div>

      

      
    </div>

		</div>
	</section>
</main>
);

export default OurTeam;