// components/Head.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const Head = ({
  title = "AMIX Wealth Management - Expert Taxation Services",
  description = "AMIX Wealth Management - Your partner in taxation, wealth management, and financial growth. Providing expert taxation advice for individuals and businesses.",
  keywords = "wealth management, taxation, corporate tax, personal finance, AMIX Wealth",
  ogImage = "https://www.amix.com.au/static/favicon/og-image.png",
  canonicalBase = "https://www.amix.com.au",
}) => {
  const location = useLocation();
  const currentUrl = `${canonicalBase}${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      {/* Basic Meta Tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      {/* SEO Meta Tags */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="AMIX Wealth Management" />
      {/* Open Graph Meta Tags for Social Sharing */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      {/* Favicon Links */}
      <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
      <link rel="manifest" href="/static/favicon/site.webmanifest" />
      <link rel="canonical" href={currentUrl} />
      {/* PWA and iOS Meta Tags */}
      <meta name="theme-color" content="#76B1A9" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      {/* Structured Data (SEO) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "AMIX Wealth Management",
          "url": {currentUrl},
          "description": description,
          "publisher": {
            "@type": "Organization",
            "name": "AMIX Wealth Management Pty Ltd"
          },
        })}
      </script>
    </Helmet>
  );
};

export default Head;