// src/pages/contactus.jsx
import React from 'react';
import Head from '../components/head';
const ContactUs = () => (
<main>
<Head
title="Contact Us - AMIX Wealth Management"
/>
	<section id="main" className="wrapper style2 contact-main">
		<div className="title">
		<h1 className="page-title">Contact Us</h1>
		<nav aria-label="breadcrumb" className="breadcrumb-container">
			<ol className="breadcrumb slim-breadcrumb">
				<li className="breadcrumb-item"><a href="/"><i className="fas fa-home"></i> AMIX</a></li>
				<li className="breadcrumb-item active" aria-current="page"><i className="fas fa-headset"></i> Contact Us</li>
			</ol>
		</nav>
		</div>
		<div className="container">
		
		
		<header className="style1">
		<h2>We’re here to connect with you!</h2>
		<p>Whether it’s in person, by phone, or email, reach out anytime. We’re committed to responding promptly and look forward to assisting you.</p>
		</header>

		<a href="https://maps.app.goo.gl/4j5uHs8ApafpjhuD7" target='_blank' className="image featured img-fluid">
			<img src="images/amix-googlemap.jpg" alt="AMIX Wealth Management" />
		</a>
		
		<div className="row">   
			<div className="col-12 col-12-medium">
					<section className="feature-list large">
						<div className="row">
							<div className="col-6 col-12-small">
								<section>
									<h3 className="icon solid fa-home">Address</h3>
									<p>
									Suite 1201<br />
									275 Alfred St,<br />
									North Sydney, NSW 2060
									</p>
								</section>
							</div>
							<div className="col-6 col-12-small">
								<section>
									<h3 className="icon solid fa-clock">Office Hours:</h3>
									<ul>
										<li>Monday - Friday: 9:00 AM - 5:00 PM</li>
										<li>Saturday - Sunday: Closed</li>
									</ul>
								</section>
							</div>
							<div className="col-6 col-12-small">
								<section>
									<h3 className="icon solid fa-envelope">Email</h3>
									<ul>
									<li>Terry: <a href="mailto:tliao@amix.com.au">tliao@amix.com.au</a></li>
									<li>Miranda: <a href="mailto:mwei@amix.com.au">mwei@amix.com.au</a></li>
									</ul>
									<p>
										
									</p>
								</section>
							</div>
							<div className="col-6 col-12-small">
								<section>
									<h3 className="icon solid fa-phone">Phone</h3>
									<ul>
									<li>Terry: 0425 157 135</li>
									<li>Miranda: 0406 608 866</li>
									</ul>
									<p>
									
									</p>
								</section>
							</div>
						</div>
					</section>

			</div>
		</div>
		</div>
	</section>
	
</main>
);

export default ContactUs;