// src/components/Header.jsx
import React from 'react';

const Header = (className) => {
    return (
    <section id="header" className={`wrapper ${className.className}`}>

        <div id="logo">
            <div className="site-name"><a href="/">AMIX Wealth Management</a></div>
            <p>Taxation | Wealth | Leverage</p>
        </div>

    
        <nav id="nav">
            <ul>
                <li className="current"><a href="/">AMIX Wealth Management</a></li>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="/our-team">Our Team</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
            </ul>
        </nav>

</section>

);
};

export default Header;
